import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { ClientDetail_Client, ClientEntityType } from '../../generated/graphql';
import ClientFundsSection from '../clientFunds/ClientFundsSection';
import { ClientFundClickEventHandler, clientFundsTableRowFragment } from '../clientFunds/ClientFundsTable';
import DetailGridItem from '../common/containers/DetailGridItem';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import ClientDeletedNote, { clientDeletedNoteFragment } from './ClientDeletedNote';
import ClientDisabledNote, { clientDisabledNoteFragment } from './ClientDisabledNote';
import ClientDocumentsSectionIntegration from './ClientDocumentsSectionIntegration';
import ClientReport from './ClientReport';
import ClientTransactionsSectionIntegration from './ClientTransactionsSectionIntegration';
import ClientTransferNoticesSectionIntegration from './ClientTransferNoticesSectionIntegration';
import { buildClientEntityTypeDisplay } from './clientUtils';
import { ClientDocumentClickEventHandler } from './documents/ClientDocumentsTable';
import { ClientTransactionClickEventHandler } from './transactions/ClientTransactionsTable';
import { TransferNoticeClickEventHandler } from './transfers/TransferNoticesTable';

export type ClientDetailData = ClientDetail_Client;

interface ClientDetailProps {
  client: ClientDetailData;
  onClickClientFund?: ClientFundClickEventHandler;
  onClickTransferNotice?: TransferNoticeClickEventHandler;
  onClickClientDocument?: ClientDocumentClickEventHandler;
  onClickClientTransaction?: ClientTransactionClickEventHandler;
}

const ClientDetail = ({
  client,
  onClickClientFund,
  onClickTransferNotice,
  onClickClientDocument,
  onClickClientTransaction,
}: ClientDetailProps) => (
  <>
    <DetailContainer>
      <ClientDisabledNote client={client} />
      <ClientDeletedNote client={client} />

      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Entity Type</DetailTitle>
            <DetailValue>{buildClientEntityTypeDisplay(client.entityType)}</DetailValue>
          </DetailField>
        </DetailGridItem>
        {client.entityType === ClientEntityType.Company && (
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Contact Name</DetailTitle>
              <DetailValue>{client.contactFullName}</DetailValue>
            </DetailField>
          </DetailGridItem>
        )}
        <DetailGridItem size="full">
          <DetailField>
            <DetailTitle>Performance</DetailTitle>
            <ClientReport clientId={client.id} />
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Changes</DetailTitle>
            <DetailValue>
              <table>
                <tbody>
                  <tr>
                    <th align="left">Deposits</th>
                    <td align="right">
                      <UsdFormat amount={client.stats.deposits} />
                    </td>
                  </tr>
                  <tr>
                    <th align="left">Withdrawals</th>
                    <td align="right">
                      <UsdFormat amount={client.stats.withdrawals} />
                    </td>
                  </tr>
                  <tr>
                    <th align="left">Fees</th>
                    <td align="right">
                      <UsdFormat amount={client.stats.fees} />
                    </td>
                  </tr>
                  <tr>
                    <th align="left">Expenses</th>
                    <td align="right">
                      <UsdFormat amount={client.stats.expenses} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Investment Basis</DetailTitle>
            <DetailValue>
              <UsdFormat amount={client.stats.investment} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Current Value</DetailTitle>
            <DetailValue>
              <UsdFormat amount={client.stats.currentValue} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Realized Gains/Losses</DetailTitle>
            <DetailValue>
              <UsdFormat amount={client.stats.realizedGainsCumulativeValue} />
              <br />
              <PercentFormat amount={client.stats.realizedGainsCumulativeRoi} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Unrealized Gains/Losses</DetailTitle>
            <DetailValue>
              <UsdFormat amount={client.stats.unrealizedGainsCumulativeValue} />
              <br />
              <PercentFormat amount={client.stats.unrealizedGainsCumulativeRoi} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>PnL</DetailTitle>
            <DetailValue>
              <UsdFormat amount={client.stats.pnlCumulativeValue} />
              <br />
              <PercentFormat amount={client.stats.pnlCumulativeRoi} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
      </Grid>
    </DetailContainer>
    <br />
    <ClientFundsSection clientFunds={client?.funds} hideClient onClickClientFund={onClickClientFund} />
    <br />
    <ClientTransferNoticesSectionIntegration clientId={client.id} onClickTransferNotice={onClickTransferNotice} />
    <ClientDocumentsSectionIntegration clientId={client.id} onClickClientDocument={onClickClientDocument} />
    <ClientTransactionsSectionIntegration clientId={client.id} onClickClientTransaction={onClickClientTransaction} />
  </>
);
export default ClientDetail;

export const clientDetailFragment = gql`
  fragment ClientDetail_Client on Client {
    id
    entityType
    contactFullName
    stats {
      deposits
      withdrawals
      investment
      fees
      expenses
      currentValue
      pnlCumulativeRoi
      pnlCumulativeValue
      realizedGainsCumulativeRoi
      realizedGainsCumulativeValue
      unrealizedGainsCumulativeRoi
      unrealizedGainsCumulativeValue
    }
    funds {
      id
      ...ClientFundsTableRow_ClientFund
    }
    createdAt

    ...ClientDisabledNote_Client
    ...ClientDeletedNote_Client
  }

  ${clientDisabledNoteFragment}
  ${clientDeletedNoteFragment}
  ${clientFundsTableRowFragment}
`;
