import { gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { ClientFundsTableRow_ClientFund } from '../../generated/graphql';
import { buildClientTitle } from '../clients/clientUtils';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { buildFundTitle } from '../funds/fundUtils';

interface ClientFundsTableProps {
  clientFunds: readonly ClientFundsTableRow_ClientFund[];
  hideClient?: boolean;
  hideFund?: boolean;
  onClickClientFundRow?: ClientFundClickEventHandler;
}

interface ClientFundsTableRowData
  extends ClientFundsTableRow_ClientFund,
    Pick<
      ClientFundsTableRow_ClientFund['stats'],
      'investment' | 'fees' | 'expenses' | 'currentValue' | 'pnlCumulativeRoi' | 'pnlCumulativeValue'
    > {
  clientName: string;
  fundName: string;
}

export type ClientFundClickEventHandler = (clientId: string, fundId: string, newTab: boolean) => void;

const ClientFundsTable = ({ hideClient, hideFund, clientFunds, onClickClientFundRow }: ClientFundsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<ClientFundsTableRowData>('fundName', 'asc');

  const showClient = !hideClient;
  const showFund = !hideFund;

  const createClickHandler = (clientId: string, fundId: string) => {
    if (!onClickClientFundRow) {
      return undefined;
    }
    return (event) => onClickClientFundRow(clientId, fundId, event.ctrlKey || event.metaKey);
  };

  const clientFundData = clientFunds.map((clientFund) => {
    const { __typename, ...stats } = clientFund.stats;
    return {
      ...clientFund,
      clientName: buildClientTitle(clientFund.client.fullName),
      fundName: buildFundTitle(clientFund.fund.name),
      ...stats,
    };
  });

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <ClientFundsTableHead
          showFund={showFund}
          showClient={showClient}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {clientFundData.length > 0 ? (
            stableSort(clientFundData, getComparator(order, orderBy)).map((clientFund) => (
              <ClientFundTableRow
                key={clientFund.id}
                clientFund={clientFund}
                showClient={showClient}
                showFund={showFund}
                onRowClick={createClickHandler(clientFund.client.id, clientFund.fund.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No funds were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default ClientFundsTable;

interface ClientFundsTableHeadProps extends Omit<SimpleSortingTableHeadProps<ClientFundsTableRowData>, 'headings'> {
  showClient: boolean;
  showFund: boolean;
}

const columnHeadings: SimpleSortingTableHeadCell<ClientFundsTableRowData>[] = [
  { key: 'clientName', label: 'LP', align: 'left' },
  { key: 'fundName', label: 'Fund' },
  { key: 'investment', label: 'Investment Basis', align: 'right' },
  { key: 'fees', label: 'Fees', align: 'right' },
  { key: 'expenses', label: 'Expenses', align: 'right' },
  { key: 'currentValue', label: 'Current Value', align: 'right' },
  { key: 'pnlCumulativeRoi', label: 'PnL', align: 'right' },
  { key: 'managementFeePercent', label: 'Management Fee %', align: 'right' },
  { key: 'performanceFeePercent', label: 'Performance Fee %', align: 'right' },
  { key: 'navId', label: 'NAV ID' },
  { key: 'createdAt', label: 'Added', align: 'right' },
];

const columnHeadingsWithoutFund = columnHeadings.filter(({ key }) => key !== 'fundName');
const columnHeadingsWithoutClient = columnHeadings.filter(({ key }) => key !== 'clientName');
const columnHeadingsWithoutFundOrClient = columnHeadings.filter(
  ({ key }) => key !== 'fundName' && key !== 'clientName',
);

const ClientFundsTableHead = ({ showClient, showFund, ...props }: ClientFundsTableHeadProps) => {
  const buildColumnHeadings = () => {
    if (showClient && showFund) {
      return columnHeadings;
    }
    if (showClient) {
      return columnHeadingsWithoutFund;
    }
    if (showFund) {
      return columnHeadingsWithoutClient;
    }
    return columnHeadingsWithoutFundOrClient;
  };

  return <SimpleSortingTableHead {...props} headings={buildColumnHeadings()} />;
};

type FundTableRowProps = {
  clientFund: ClientFundsTableRowData;
  showClient: boolean;
  showFund: boolean;
  onRowClick?: React.MouseEventHandler;
};

export const ClientFundTableRow = ({ showClient, showFund, clientFund, onRowClick }: FundTableRowProps) => {
  const { formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      {showClient && <TableCell>{clientFund.clientName}</TableCell>}
      {showFund && <TableCell>{clientFund.fundName}</TableCell>}
      <TableCell align="right">
        <UsdFormat amount={clientFund.stats.investment} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={clientFund.stats.fees} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={clientFund.stats.expenses} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={clientFund.stats.currentValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={clientFund.stats.pnlCumulativeRoi} />
        <br />
        <UsdFormat amount={clientFund.stats.pnlCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={clientFund.managementFeePercent} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={clientFund.performanceFeePercent} />
      </TableCell>
      <TableCell>{clientFund.navId}</TableCell>
      <TableCell align="right">{formatShortDateTime(clientFund.createdAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const clientFundsTableRowFragment = gql`
  fragment ClientFundsTableRow_ClientFund on ClientFund {
    id
    client {
      id
      fullName
    }
    fund {
      id
      name
    }
    managementFeePercent
    performanceFeePercent
    navId
    stats {
      investment
      fees
      expenses
      currentValue
      pnlCumulativeRoi
      pnlCumulativeValue
    }
    createdAt
  }
`;
