import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import React from 'react';
import { ClientFundDetail_ClientFund } from '../../generated/graphql';
import { buildLpPath } from '../clients/clientPaths';
import DetailGridItem from '../common/containers/DetailGridItem';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { buildFundPath } from '../funds/fundsPaths';
import ClientFundReport from './ClientFundReport';

export interface ClientFundDetailProps {
  clientFund: ClientFundDetail_ClientFund;
}

const ClientFundDetail = ({ clientFund }: ClientFundDetailProps) => (
  <DetailContainer>
    <Grid container justifyContent="left" spacing={2}>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>LP</DetailTitle>
          <DetailValue>
            <RouteLink path={buildLpPath(clientFund.client.id)}>{clientFund.client.fullName}</RouteLink>
          </DetailValue>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Fund</DetailTitle>
          <DetailValue>
            <RouteLink path={buildFundPath(clientFund.fund.id)}>{clientFund.fund.name}</RouteLink>
          </DetailValue>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>NAV ID</DetailTitle>
          <DetailValue>{clientFund.navId || 'not set'}</DetailValue>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Management Fee %</DetailTitle>
          <DetailValue>
            <PercentFormat amount={clientFund.managementFeePercent} />{' '}
            {clientFund.fund.managementFeePercent !== clientFund.managementFeePercent ? (
              <>
                (overridden from <PercentFormat amount={clientFund.fund.managementFeePercent} />)
              </>
            ) : null}
          </DetailValue>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Performance Fee %</DetailTitle>
          <DetailValue>
            <PercentFormat amount={clientFund.performanceFeePercent} />{' '}
            {clientFund.fund.performanceFeePercent !== clientFund.performanceFeePercent ? (
              <>
                (overridden from <PercentFormat amount={clientFund.fund.performanceFeePercent} />)
              </>
            ) : null}
          </DetailValue>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem size="full">
        <DetailField>
          <DetailTitle>Performance</DetailTitle>
          <ClientFundReport clientId={clientFund.client.id} fundId={clientFund.fund.id} />
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Deposits & Withdrawals</DetailTitle>
          <DetailValue>
            <table>
              <tbody>
                <tr>
                  <th align="left">Deposits</th>
                  <td align="right">
                    <UsdFormat amount={clientFund.stats.deposits} />
                  </td>
                </tr>
                <tr>
                  <th align="left">Withdrawals</th>
                  <td align="right">
                    <UsdFormat amount={clientFund.stats.withdrawals} />
                  </td>
                </tr>
                <tr>
                  <th align="left">Fees</th>
                  <td align="right">
                    <UsdFormat amount={clientFund.stats.fees} />
                  </td>
                </tr>
                <tr>
                  <th align="left">Expenses</th>
                  <td align="right">
                    <UsdFormat amount={clientFund.stats.expenses} />
                  </td>
                </tr>
              </tbody>
            </table>
          </DetailValue>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Investment Basis</DetailTitle>
          <DetailValue>
            <UsdFormat amount={clientFund.stats.investment} />
          </DetailValue>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Current Value</DetailTitle>
          <DetailValue>
            <UsdFormat amount={clientFund.stats.currentValue} />
          </DetailValue>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Realized Gains/Losses</DetailTitle>
          <DetailValue>
            <UsdFormat amount={clientFund.stats.realizedGainsCumulativeValue} />
            <br />
            <PercentFormat amount={clientFund.stats.realizedGainsCumulativeRoi} />
          </DetailValue>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Unrealized Gains/Losses</DetailTitle>
          <DetailValue>
            <UsdFormat amount={clientFund.stats.unrealizedGainsCumulativeValue} />
            <br />
            <PercentFormat amount={clientFund.stats.unrealizedGainsCumulativeRoi} />
          </DetailValue>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>PnL</DetailTitle>
          <DetailValue>
            <UsdFormat amount={clientFund.stats.pnlCumulativeValue} />
            <br />
            <PercentFormat amount={clientFund.stats.pnlCumulativeRoi} />
          </DetailValue>
        </DetailField>
      </DetailGridItem>
    </Grid>
  </DetailContainer>
);
export default ClientFundDetail;

export const clientFundDetailFragment = gql`
  fragment ClientFundDetail_ClientFund on ClientFund {
    id
    client {
      id
      fullName
    }
    fund {
      id
      name
      managementFeePercent
      performanceFeePercent
    }
    managementFeePercent
    performanceFeePercent
    navId
    stats {
      deposits
      withdrawals
      investment
      fees
      expenses
      currentValue
      pnlCumulativeRoi
      pnlCumulativeValue
      realizedGainsCumulativeRoi
      realizedGainsCumulativeValue
      unrealizedGainsCumulativeRoi
      unrealizedGainsCumulativeValue
    }
    createdAt
  }
`;
