export enum ReportPropertyType {
  Id = 'Id',
  Usd = 'usd',
  Percent = 'percent',
  Integer = 'integer',
  Number = 'number',
  Bips = 'bips',
  Date = 'date',
  DateTime = 'datetime',
  Unknown = 'unknown',
}

export enum ReportPropertyScale {
  Smallest = 'smallest',
  Smaller = 'smaller',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Larger = 'larger',
  Largest = 'largest',
}
