import { gql } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { FundsTableRow_Fund } from '../../generated/graphql';
import DeletedIcon from '../common/icons/DeletedIcon';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import FloatFormat from '../common/typography/FloatFormat';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { buildFundTitle } from './fundUtils';

interface FundsTableProps {
  funds: readonly FundsTableRow_Fund[];
  onClickFundRow?: FundClickEventHandler;
}

interface FundsTableRowData
  extends FundsTableRow_Fund,
    Pick<
      FundsTableRow_Fund['stats'],
      'clientInvestment' | 'clientFees' | 'clientExpenses' | 'currentValue' | 'pnlCumulativeRoi' | 'pnlCumulativeValue'
    > {
  beta: number | null;
  maxDrawdown: number | null;
  sharpeRatio: number | null;
}

export type FundClickEventHandler = (fundId: string, newTab: boolean) => void;

const FundsTable = ({ funds, onClickFundRow }: FundsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<FundsTableRowData>('name', 'asc');

  const createClickHandler = (fundId: string) => {
    if (!onClickFundRow) {
      return undefined;
    }
    return (event) => onClickFundRow(fundId, event.ctrlKey || event.metaKey);
  };

  const fundData = funds.map((fund): FundsTableRowData => {
    const { __typename, beta, maxDrawdown, sharpeRatio, ...stats } = fund.stats;
    return {
      ...fund,
      ...stats,
      beta: beta.allTime,
      maxDrawdown: maxDrawdown.allTime,
      sharpeRatio: sharpeRatio.allTime,
    };
  });

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <FundsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {fundData.length > 0 ? (
            stableSort(fundData, getComparator(order, orderBy)).map((fund) => (
              <FundTableRow key={fund.id} fund={fund} onRowClick={createClickHandler(fund.id)} />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No funds were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default FundsTable;

type FundsTableHeadProps = Omit<SimpleSortingTableHeadProps<FundsTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<FundsTableRowData>[] = [
  { key: 'name', label: 'Name' },
  { key: 'clientInvestment', label: 'Investment Basis', align: 'right' },
  { key: 'clientFees', label: 'LP Fees', align: 'right' },
  { key: 'clientExpenses', label: 'LP Expenses', align: 'right' },
  { key: 'currentValue', label: 'Current Value', align: 'right' },
  { key: 'pnlCumulativeRoi', label: 'PnL', align: 'right' },
  { key: 'sharpeRatio', label: 'Sharpe Ratio', align: 'right' },
  { key: 'beta', label: 'Beta', align: 'right' },
  { key: 'maxDrawdown', label: 'Max Drawdown', align: 'right' },
];

const FundsTableHead = (props: FundsTableHeadProps) => <SimpleSortingTableHead {...props} headings={columnHeadings} />;

type FundTableRowProps = {
  fund: FundsTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const FundTableRow = ({ fund, onRowClick }: FundTableRowProps) => {
  const theme = useTheme();
  const { formatDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>
        {buildFundTitle(fund.name)}&nbsp;
        {fund.deletedAt ? (
          <TooltipItem title={`Deleted on ${formatDateTime(fund.deletedAt)}`}>
            <DeletedIcon style={{ fontSize: theme.typography.body2.fontSize }} />
          </TooltipItem>
        ) : null}
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={fund.stats.clientInvestment} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={fund.stats.clientFees} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={fund.stats.clientExpenses} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={fund.stats.currentValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={fund.stats.pnlCumulativeRoi} />
        <br />
        <UsdFormat amount={fund.stats.pnlCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <FloatFormat amount={fund.sharpeRatio} decimalScale={2} />
      </TableCell>
      <TableCell align="right">
        <FloatFormat amount={fund.beta} decimalScale={2} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={fund.maxDrawdown} />
      </TableCell>
    </AlternatingTableRow>
  );
};

export const fundsTableRowFragment = gql`
  fragment FundsTableRow_Fund on Fund {
    id
    name
    stats {
      clientInvestment
      clientFees
      clientExpenses
      currentValue
      pnlCumulativeRoi
      pnlCumulativeValue
      beta {
        allTime
      }
      maxDrawdown {
        allTime
      }
      sharpeRatio {
        allTime
      }
    }
    createdAt
    deletedAt
  }
`;
